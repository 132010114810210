/* DON'T EDIT THIS FILE: edit original and run build again */ export const canAccessOnboardingAdminSections =
  "canAccessOnboardingAdminSections";
export const canAccessOnboardingLog = "canAccessOnboardingLog";
export const canAccessOnboardings = "canAccessOnboardings";
export const canAccessRedlinkData = "canAccessRedlinkData";
export const canAccessRenaperData = "canAccessRenaperData";
export const canAddCommentOnboarding = "canAddCommentOnboarding";
export const canAddOnboardings = "canAddOnboardings";
export const canChangeToAllStatuses = "canChangeToAllStatuses";
export const canCreateVideoSelfieRequests = "canCreateVideoSelfieRequests";
export const canDeleteVideoSelfies = "canDeleteVideoSelfies";
export const canEditOtherProducerOnboardings =
  "canEditOtherProducerOnboardings";
export const canEditOtherSalesOnboardings = "canEditOtherSalesOnboardings";
export const canEditOnboardingsWithoutAssigneeOrProducer =
  "canEditOnboardingsWithoutAssigneeOrProducer";
export const canEditOtherUsersOnboardings = "canEditOtherUsersOnboardings";
export const canEditSubmittedOnboardings = "canEditSubmittedOnboardings";
export const canListOnboardings = "canListOnboardings";
export const canPublishToAune = "canPublishToAune";
export const canPublishToEsco = "canPublishToEsco";

export const canViewOnboardingsWithCommonStatuses =
  "canViewOnboardingsWithCommonStatuses";
export const canViewOnboardingsWithInfoRequestStatus =
  "canViewOnboardingsWithInfoRequestStatus";
export const canViewOnboardingsWithInitialLoadStatus =
  "canViewOnboardingsWithInitialLoadStatus";
export const canViewVideoSelfies = "canViewVideoSelfies";
export const canBackupOnboardings = "canBackupOnboardings";
export const canDeleteOnboardingComments = "canDeleteOnboardingComments";
