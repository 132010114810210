/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../../framework/live-form/ui/text-viewer.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
} from "../../../framework/live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../../framework/live-form/ui/use-live-after-blur-text-input.tsx";
import { TokenLiveField } from "./live-field.ts";
import { TokenInput } from "./token-input.tsx";
import { tokenLiveFieldType } from "./type.ts";

const Input: LiveFieldInput<unknown> = (fieldInfo) => {
  const liveField = fieldInfo.liveCell.getLiveField();
  if (!(liveField instanceof TokenLiveField)) {
    throw new Error(
      `Expected a TokenLiveField, but got ${liveField?.constructor?.name}`
    );
  }
  return (
    <TokenInput
      {...useLiveAfterBlurTextInput(fieldInfo)}
      length={liveField.getLength()}
    />
  );
};

export const tokenLiveFieldRenderers: LiveFieldRenderer<unknown> = {
  type: tokenLiveFieldType,
  Input,
  Viewer: LiveTextViewer,
};
