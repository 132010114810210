/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentExtension,
  DocumentToFulfill,
} from "../../../credit/autocomplete-files/types.ts";

export const documentsToFulfill: DocumentToFulfill[] = [
  {
    label: "Información básica agro",
    filename: "informacion_basica_agro.xlsx",
    field: "informacion-basica-agro-xlsx",
    path: "/sgr-documentation/bind/informacion_basica_agro.xlsx",
    type: DocumentExtension.xlsx,
  },
];
