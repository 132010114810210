/* DON'T EDIT THIS FILE: edit original and run build again */ export enum DocumentExtension {
  xlsx,
  docx,
}

export type DocumentToFulfill = {
  label: string;
  filename: string;
  field: string;
  path: string;
  type: DocumentExtension;
};

export type XlsxFileData = Record<string, string | number>;

export type DocxFileData = Record<
  string,
  string | number | Array<object> | undefined
>;
