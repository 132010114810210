/* DON'T EDIT THIS FILE: edit original and run build again */ import { InlineEntityInput } from "../../credit/entity-ui/inline-entity-input.tsx";
import {
  buildInlineEntityDefaultValue,
  useInlineEntityState,
} from "../../credit/entity-ui/inline-entity-state.ts";
import { InlineEntityViewer } from "../../credit/entity-ui/inline-entity-viewer.tsx";
import { InlineEntity } from "../../credit/entity/inline-entity-type.ts";
import { isInlineEntityEmpty } from "../../credit/entity/inline-entity.ts";
import { notNull } from "../../framework/core/not-null.ts";
import { FolderLiveData } from "../../framework/live-form-folder-live-data/folder-live-data.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../framework/live-form/ui/types.ts";
import { EntityLiveField } from "./live-field.ts";
import { entityLiveFieldType } from "./type.ts";

const LiveInlineEntityInput: LiveFieldInput<FolderLiveData> = ({
  liveCell,
  getLiveFormInputFactory,
  saveField,
}) => {
  const liveField = liveCell.getLiveField();
  if (!(liveField instanceof EntityLiveField)) {
    throw new Error("liveField must be an instance of EntityLiveField");
  }
  const liveData = liveCell.getLiveData();
  const {
    includeName,
    includeExtraNameFields,
    allowForeignEntities,
    allowDomesticEntities,
    includeDateOfBirth,
    allowEntityTypes,
    useDni,
  } = liveField;
  const inlineEntityHandler = notNull(liveData.inlineEntityHandler);
  if (!allowDomesticEntities && !allowForeignEntities) {
    throw new Error(
      "allowDomesticEntities and allowForeignEntities cannot both be false"
    );
  }
  const field = notNull(liveField.getField());
  const personInlineEntityId = (liveCell.getValue() as string) ?? null;
  const personInlineEntity = personInlineEntityId
    ? { ...inlineEntityHandler.getInlineEntityById(personInlineEntityId) }
    : buildInlineEntityDefaultValue({
        allowEntityTypes,
      });
  const personInlineEntityState = useInlineEntityState({
    value: personInlineEntity,
    setValue: async (value) => {
      await saveField(
        field,
        !value || isInlineEntityEmpty(value)
          ? null
          : (
              await inlineEntityHandler.upsertInlineEntity(value)
            ).id
      );
    },
    options: {
      required: liveCell.isRequired(),
      validate: liveCell.getShowErrors(),
      includeName,
      includeExtraNameFields,
      includeDateOfBirth,
      dateOfBirthRequired: includeDateOfBirth,
      allowEntityTypes,
      allowForeignEntities,
      allowDomesticEntities,
      useDni,
    },
    baseFolder: liveData.baseFolder,
  });
  return (
    <InlineEntityInput
      autoFocus={liveField.getAutoFocus()}
      className={"mt-3 " + liveField.getClassName()}
      inlineEntityState={personInlineEntityState}
      getLiveFormInputFactory={getLiveFormInputFactory}
    />
  );
};

const Viewer: LiveFieldViewer<FolderLiveData> = ({
  liveCell,
  getLiveFormInputFactory,
}) => {
  const liveField = liveCell.getLiveField();
  if (!(liveField instanceof EntityLiveField)) {
    throw new Error("liveField must be an instance of EntityLiveField");
  }
  const liveData = liveCell.getLiveData();
  const inlineEntityHandler = notNull(liveData.inlineEntityHandler);
  const {
    includeName = true,
    includeExtraNameFields = includeName,
    includeDateOfBirth = true,
  } = liveField;
  const personInlineEntityId = (liveCell.getValue() as string) ?? null;
  // I don't actually think getInlineEntityById shuld return null in this case, since you're passing in an id, but I don't want to change the behavior now
  const personInlineEntity: Partial<InlineEntity> =
    (personInlineEntityId
      ? inlineEntityHandler.getInlineEntityById(personInlineEntityId) ?? {}
      : null) ?? {};
  return (
    <InlineEntityViewer
      inlineEntity={personInlineEntity}
      includeName={includeName}
      includeExtraNameFields={includeExtraNameFields}
      includeDateOfBirth={includeDateOfBirth}
      baseFolder={liveData.baseFolder}
      specificFolder={liveData.specificFolder}
      getLiveFormInputFactory={getLiveFormInputFactory}
      inlineEntityHandler={inlineEntityHandler}
    />
  );
};

export const entityLiveFieldRenderers: LiveFieldRenderer<FolderLiveData> = {
  type: entityLiveFieldType,
  Input: LiveInlineEntityInput,
  Viewer,
};
