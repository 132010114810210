/* DON'T EDIT THIS FILE: edit original and run build again */ import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";
import { GenericIconProps } from "../../../framework/theme-icons/generic-icon.tsx";
import { ComponentType } from "react";

class SgrB2cOptions implements B2cOptions {
  async getB2cNavbarPageSmallLogo(): Promise<ComponentType<GenericIconProps>> {
    return (await import("./b2c-sidebar-page-small-logo.tsx")).default;
  }

  allowPersonalAccountsAsEndClients(): boolean {
    return false;
  }

  endClientLandingBackgroundPath(): string | null {
    return "/sgr_background.jpg";
  }

  endClientHasWhatsappButton(): boolean {
    return false;
  }

  endClientHasRiskPreview(): boolean {
    return false;
  }

  endClientNavbarColor(): string | null {
    return "#F2F5F2";
  }

  endClientContactAdvisor(): boolean {
    return true;
  }

  getAdvisorPhoneNumberForB2CCampaigns(): string | null {
    return "5491124646861";
  }
}

export const sgrB2cOptions = new SgrB2cOptions();
