/* DON'T EDIT THIS FILE: edit original and run build again */ export const canAccessLines = "canAccessLines";
export const canListLines = "canListLines";
export const canAccessLinesSeparatePagesView =
  "canAccessLinesSeparatePagesView";
export const canAccessLinesPresentationView = "canAccessLinesPresentationView";
export const canAccessLinesPresentationViewPage =
  "canAccessLinesPresentationViewPage";
export const canAccessLinesPresentationViewOnPresentationStatus =
  "canAccessLinesPresentationViewOnPresentationStatus";
export const canOpenMultipleLines = "canOpenMultipleLines";
export const canAccessLineAnalisis = "canAccessLineAnalisis";
export const accessAdminLine = "accessAdminLine";
export const canViewFullInfoLineAdminSections =
  "canViewFullInfoLineAdminSections";
export const canViewPresentationLineAdminSections =
  "canViewPresentationLineAdminSections";
export const canViewInternalLineStatusSection =
  "canViewInternalLineStatusSection";
export const canUseLineFilters = "canUseLineFilters";
export const canEditOtherUserLines = "canEditOtherUserLines";
export const canEditOtherSalesLines = "canEditOtherSalesLines";
export const canEditOtherProducerLines = "canEditOtherProducerLines";
export const canEditLinesWithoutAssigneeOrProducer =
  "canEditLinesWithoutAssigneeOrProducer";
export const canViewInternalLineCommonSections =
  "canViewInternalLineCommonSections";
export const canAccessRiskPreview = "canAccessRiskPreview";
export const canListNonSmeLines = "canListNonSmeLines";
export const canViewLineInstruments = "canViewLineInstruments";
export const canViewLineFinancingNeeds = "canViewLineFinancingNeeds";
export const canAddConceptGuarantor = "canAddConceptGuarantor";
export const canEditSubmittedLines = "canEditSubmittedLines";
export const canViewContactInfoSection = "canViewContactInfoSection"; // FIXME: deprecated ?
export const canAddCommentForAnySource = "canAddCommentForAnySource";

export const canCreateLinesInSurveyingStatus =
  "canCreateLinesInSurveyingStatus";
export const canViewNosisSection = "canViewNosisSection";
export const canQueryLufe = "canQueryLufe";
export const canAutocompleteLufe = "canAutocompleteLufe";
export const canViewLinePreApprovals = "canViewLinePreApprovals";
export const canCopyLinesToOtherTenants = "canCopyLinesToOtherTenants";
export const canTransitionToAnalysisStatus = "canTransitionToAnalysisStatus";
export const canEditLineOfficeBranch = "canEditLineOfficeBranch";
export const canRequestMatchfinAnalystsReview =
  "canRequestMatchfinAnalystsReview";

export const canAccessLineDataLoadPages = "canAccessLineDataLoadPages";
export const canViewLineHasChosenAlyc = "canViewLineHasChosenAlyc";
export const canViewLineChooseYourAlycSection =
  "canViewLineChooseYourAlycSection";
export const canViewFinancerStatuses = "canViewFinancerStatuses";
export const canDeleteLineComments = "canDeleteLinesComments";

export const canListLinesWithCommonStatuses = "canListLinesWithCommonStatuses";
export const canViewLinesWithCommonStatuses = "canViewLinesWithCommonStatuses";
export const canListLinesWithPresentationStatus =
  "canListLinesWithPresentationStatus";
export const canViewLinesWithPresentationStatus =
  "canViewLinesWithPresentationStatus";
export const canListLinesWithInfoRequestStatus =
  "canListLinesWithInfoRequestStatus";
export const canViewLinesWithInfoRequestStatus =
  "canViewLinesWithInfoRequestStatus";
export const canListLinesWithInitialLoadStatus =
  "canListLinesWithInitialLoadStatus";
export const canViewLinesWithInitialLoadStatus =
  "canViewLinesWithInitialLoadStatus";
export const canListLinesWithDataLoadedStatus =
  "canListLinesWithDataLoadedStatus";
export const canViewLinesWithDataLoadedStatus =
  "canViewLinesWithDataLoadedStatus";

export const canViewLiveRisk = "canViewLiveRisk";
