/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentToFulfill } from "../../../credit/autocomplete-files/types.ts";
import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { argenpymesTenant } from "./code.ts";
import { documentsToFulfill } from "./documents-to-fulfill.ts";
import { argenpymesFullLabel } from "./full-label.ts";
import { argenpymesLabel } from "./label.ts";

class ArgenpymesTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return argenpymesTenant;
  }

  getPathLabel(): string | null {
    return "argenpymes";
  }

  getLabel(): string {
    return argenpymesLabel;
  }

  getFullLabel(): string {
    return argenpymesFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "33-71492413-9";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  getDocumentsToFulfill(): DocumentToFulfill[] {
    return documentsToFulfill;
  }

  hasLufe(): boolean {
    return true;
  }
}

const argenpymesTenantHandler = new ArgenpymesTenantHandler();

export default argenpymesTenantHandler;
