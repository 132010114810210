/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentExtension,
  DocumentToFulfill,
} from "../../../credit/autocomplete-files/types.ts";

export const documentsToFulfill: DocumentToFulfill[] = [
  {
    label: "Compras y ventas",
    filename: "compras_ventas.xlsx",
    field: "compras-ventas-xlsx",
    path: "/sgr-documentation/garantizar/compras_ventas.xlsx",
    type: DocumentExtension.xlsx,
  },
  {
    label: "Manifestación de bienes",
    filename: "manifestacion_bienes.xlsx",
    field: "manifestacion-bienes-xlsx",
    path: "/sgr-documentation/garantizar/manifestacion_bienes.xlsx",
    type: DocumentExtension.xlsx,
  },
  {
    label: "Plan de siembra",
    filename: "plan_siembra.xlsx",
    field: "plan-siembra-xlsx",
    path: "/sgr-documentation/garantizar/plan_siembra.xlsx",
    type: DocumentExtension.xlsx,
  },
  {
    label: "Solicitud",
    filename: "solicitud.xlsx",
    field: "solicitud-xlsx",
    path: "/sgr-documentation/garantizar/solicitud.xlsx",
    type: DocumentExtension.xlsx,
  },
];
